import {checkValid} from "../../../../globalModels/functions/ModelsValidate";
import {
  DHL_PERCENT_BY_SETTING,
  DHL_PERCENT_UA_SETTING,
  DHL_PERCENT_US_SETTING, FEDEX_PERCENT_BALTIC,
  FEDEX_PERCENT_DEFAULT, FEDEX_PERCENT_GB, FEDEX_PERCENT_POLAND,
  FEDEX_PERCENT_RUSSIA, NOVAPOSHTA_GLOBAL_PERCENT_DEFAULT,
  OUTPUT_COMMISSION_1000_SETTING, OUTPUT_COMMISSION_3000_SETTING,
  OUTPUT_COMMISSION_500_SETTING,
  PERCENT_CDEK_DEFAULT,
  TNT_PERCENT_DEFAULT,
  TNT_PERCENT_VIP
} from "../../../../../staticData/staticVariables";
import _ from 'lodash'

export function ShippingCompanies() {
  this.checkValid = checkValid

  this.data = {
    outputCommission500: '',
    outputCommission1000: '',
    outputCommission3000: '',

    ukrainian: '',
    belarus: '',
    usa: '',

    CDEKShippingPrice: '',

    TNTPercentDefault: '',

    FedexPercentDefault: '',
    FedexRussiaPercentDefault: '',
    FedexBalticPercentDefault: '',
    FedexPolandPercentDefault: '',
    FedexGBPercentDefault: '',

    NovaPoshtaGlobalPercentDefault: '',

  }

  this.validation = {
    ukrainian: false,
    belarus: false,
    usa: false,

    TNTPercentDefault: false,
    FedexPercentDefault: false,
    FedexRussiaPercentDefault: false,
    FedexBalticPercentDefault: false,
    FedexPolandPercentDefault: false,
    CDEKShippingPrice: false,
    NovaPoshtaGlobalPercentDefault: false,
  }

  this.validationTranslate = {
    ukrainian: '',
    belarus: '',
    usa: '',

    TNTPercentDefault: '',
    FedexPercentDefault: '',
    FedexRussiaPercentDefault: '',
    FedexBalticPercentDefault: '',
    FedexPolandPercentDefault: '',
    CDEKShippingPrice: '',
    NovaPoshtaGlobalPercentDefault: '',
  }

  this.validationTxt = {
    ukrainian: false,
    belarus: false,
    usa: false,

    TNTPercentDefault: false,
    FedexPercentDefault: false,
    FedexRussiaPercentDefault: false,
    FedexBalticPercentDefault: false,
    FedexPolandPercentDefault: false,
    CDEKShippingPrice: false,
    NovaPoshtaGlobalPercentDefault: false,
  }

}

/*****************   SETTERS   ******************/
ShippingCompanies.prototype.setOutputCommission500 = function (val) {
  this.data.outputCommission500 = val;
}

ShippingCompanies.prototype.setOutputCommission1000 = function (val) {
  this.data.outputCommission1000 = val;
}

ShippingCompanies.prototype.setOutputCommission3000 = function (val) {
  this.data.outputCommission3000 = val;
}

ShippingCompanies.prototype.setUkrainian = function (val) {
  this.data.ukrainian = val;
}

ShippingCompanies.prototype.setBelarus = function (val) {
  this.data.belarus = val;
}

ShippingCompanies.prototype.setUSA = function (val) {
  this.data.usa = val;
}

ShippingCompanies.prototype.setCDEKShippingPrice = function (val) {
  this.data.CDEKShippingPrice = val;
}

ShippingCompanies.prototype.setTNTPercentDefault = function (val) {
  this.data.TNTPercentDefault = val;
}

ShippingCompanies.prototype.setFedexPercentDefault = function (val) {
  this.data.FedexPercentDefault = val;
}

ShippingCompanies.prototype.setFedexRussiaPercentDefault = function (val) {
  this.data.FedexRussiaPercentDefault = val;
}

ShippingCompanies.prototype.setFedexBalticPercentDefault = function (val) {
  this.data.FedexBalticPercentDefault = val;
}

ShippingCompanies.prototype.setFedexPolandPercentDefault = function (val) {
  this.data.FedexPolandPercentDefault = val;
}

ShippingCompanies.prototype.setFedexGBPercentDefault = function (val) {
  this.data.FedexGBPercentDefault = val;
}

ShippingCompanies.prototype.setTNTPercentVip = function (val) {
  this.data.TNTPercentVip = val;
}

ShippingCompanies.prototype.setNovaPoshtaGlobalPercentDefault = function (val) {
  this.data.NovaPoshtaGlobalPercentDefault = val;
}


ShippingCompanies.prototype.setShippingCompaniesItem = function (val) {
  this.setOutputCommission500(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_500_SETTING}).value).toFixed(2))
  this.setOutputCommission1000(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_1000_SETTING}).value).toFixed(2))
  this.setOutputCommission3000(parseFloat(_.find(val, {name: OUTPUT_COMMISSION_3000_SETTING}).value).toFixed(2))

  this.setUkrainian(parseFloat(_.find(val, {name: DHL_PERCENT_UA_SETTING}).value).toFixed(2))
  this.setUSA(parseFloat(_.find(val, {name: DHL_PERCENT_US_SETTING}).value).toFixed(2))
  this.setBelarus(parseFloat(_.find(val, {name: DHL_PERCENT_BY_SETTING}).value).toFixed(2))

  this.setCDEKShippingPrice(parseFloat(_.find(val, {name: PERCENT_CDEK_DEFAULT}).value).toFixed(2))

  this.setTNTPercentDefault(parseFloat(_.find(val, {name: TNT_PERCENT_DEFAULT}).value).toFixed(2))
  this.setTNTPercentVip(parseFloat(_.find(val, {name: TNT_PERCENT_VIP}).value).toFixed(2))

  this.setFedexPercentDefault(parseFloat(_.find(val, {name: FEDEX_PERCENT_DEFAULT}).value).toFixed(2))
  this.setFedexRussiaPercentDefault(parseFloat(_.find(val, {name: FEDEX_PERCENT_RUSSIA}).value).toFixed(2))
  this.setFedexBalticPercentDefault(parseFloat(_.find(val, {name: FEDEX_PERCENT_BALTIC}).value).toFixed(2))
  this.setFedexPolandPercentDefault(parseFloat(_.find(val, {name: FEDEX_PERCENT_POLAND})?.value).toFixed(2))
  this.setFedexGBPercentDefault(parseFloat(_.find(val, {name: FEDEX_PERCENT_GB})?.value).toFixed(2))
  this.setNovaPoshtaGlobalPercentDefault(parseFloat(_.find(val, {name: NOVAPOSHTA_GLOBAL_PERCENT_DEFAULT})?.value).toFixed(2))
}




/*****************   GETTERS   ******************/
ShippingCompanies.prototype.getOutputCommission500 = function (){
  return this.data.outputCommission500;
}

ShippingCompanies.prototype.getOutputCommission1000 = function (){
  return this.data.outputCommission1000;
}

ShippingCompanies.prototype.getOutputCommission3000 = function (){
  return this.data.outputCommission3000;
}

ShippingCompanies.prototype.getUkrainian = function (){
  return this.data.ukrainian;
}

ShippingCompanies.prototype.getBelarus = function (){
  return this.data.belarus;
}

ShippingCompanies.prototype.getUSA = function (){
  return this.data.usa;
}

ShippingCompanies.prototype.getCDEKShippingPrice = function (){
  return this.data.CDEKShippingPrice;
}

ShippingCompanies.prototype.getTNTPercentDefault = function (){
  return this.data.TNTPercentDefault;
}

ShippingCompanies.prototype.getFedexPercentDefault = function (){
  return this.data.FedexPercentDefault;
}

ShippingCompanies.prototype.getFedexRussiaPercentDefault = function (){
  return this.data.FedexRussiaPercentDefault;
}

ShippingCompanies.prototype.getFedexBalticPercentDefault = function (){
  return this.data.FedexBalticPercentDefault;
}

ShippingCompanies.prototype.getFedexPolandPercentDefault = function (){
  return this.data.FedexPolandPercentDefault;
}

ShippingCompanies.prototype.getFedexGBPercentDefault = function (){
  return this.data.FedexGBPercentDefault;
}

ShippingCompanies.prototype.getTNTPercentVip = function (){
  return this.data.TNTPercentVip;
}

ShippingCompanies.prototype.getNovaPoshtaGlobalPercentDefault = function (){
  return this.data.NovaPoshtaGlobalPercentDefault;
}


/******************  PREPARE DATA  *****************/

ShippingCompanies.prototype.prepareData = function () {

  return {
    "ukrainian": this.getUkrainian(),
    "usa": this.getUSA(),
    "belarus": this.getBelarus(),

    "outputCommission500": this.getOutputCommission500(),
    "outputCommission1000": this.getOutputCommission1000(),
    "outputCommission3000": this.getOutputCommission3000(),

    "CDEKShippingPrice": this.getCDEKShippingPrice(),
    "TNTPercentDefault": this.getTNTPercentDefault(),
    "TNTPercentVip": this.getTNTPercentVip(), "FedexPercentDefault": this.getFedexPercentDefault(),
    "FedexRussiaPercentDefault": this.getFedexRussiaPercentDefault(),
    "FedexBalticPercentDefault": this.getFedexBalticPercentDefault(),
    "FedexPolandPercentDefault": this.getFedexPolandPercentDefault(),
    "FedexGBPercentDefault": this.getFedexGBPercentDefault(),
    "NovaPoshtaGlobalPercentDefault": this.getNovaPoshtaGlobalPercentDefault(),
  }
}
